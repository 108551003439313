import { Module, VuexModule, Mutation, Action } from 'vuex-module-decorators'
import { Actions, Mutations } from '@/store/enums/StoreEnums'
import ApiService from '@/services/ApiService'

type CerfaParams = {
  [key: string]: {
    label: string,
    value: string,
  }[]
}

@Module
export default class CerfaModule extends VuexModule {
  cerfaParams: CerfaParams = {}

  get getCerfaParams () {
    return this.cerfaParams
  }

  @Mutation
  [Mutations.SAVE_CERFA_PARAMS] (payload: CerfaParams) {
    this.cerfaParams = payload
  }

  @Action
  async [Actions.FETCH_CERFA_PARAMS] (id: string) {
    const res = await ApiService.get<CerfaParams>('/admissibles/cerfa_params/' + id)
    if (res?.data) {
      this.context.commit(Mutations.SAVE_CERFA_PARAMS, res.data)
    }
  }
}

export {
  CerfaParams
}
