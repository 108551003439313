
import { defineComponent } from 'vue'
import { useStore } from 'vuex'
import { Actions, Mutations } from '@/store/enums/StoreEnums'
import { themeMode } from '@/config/config'

export default defineComponent({
  name: 'kt-theme-switcher',
  component: {},
  setup () {
    const store = useStore()

    const setMode = (mode: string) => {
      let configMode = mode
      if (mode === 'system') {
        configMode = window.matchMedia('(prefers-color-scheme: dark)') ? 'dark' : 'light'
      }

      store.commit(Mutations.SET_LAYOUT_CONFIG_PROPERTY, {
        property: 'general.mode',
        value: configMode
      })

      store.dispatch(Actions.SET_THEME_MODE_ACTION, configMode)
    }

    return {
      themeMode,
      setMode
    }
  }
})
