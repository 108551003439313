
import { defineComponent } from 'vue'
import { useRoute } from 'vue-router'
import SidebarMenuConfig from '@/config/SidebarMenuConfig'
import { headerMenuIcons } from '@/config/config'

export default defineComponent({
  name: 'MenuPages',
  components: {},
  setup () {
    const route = useRoute()

    const hasActiveChildren = (match: string) => {
      return route.path.indexOf(match) !== -1
    }

    return {
      hasActiveChildren,
      headerMenuIcons,
      SidebarMenuConfig
    }
  }
})
