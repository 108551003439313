
import { defineComponent } from 'vue'
import HomeMenuConfig from '@/config/MainMenuConfig'

export default defineComponent({
  name: 'basic-card',
  data () {
    return {
      HomeMenuConfig
    }
  },
  props: {
    title: String,
    noInlineBorder: {
      type: Boolean,
      default: false
    },
    explicitContainment: {
      type: Boolean,
      default: false
    }
  }
})
