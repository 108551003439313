import { toDisplayString as _toDisplayString, normalizeClass as _normalizeClass, openBlock as _openBlock, createElementBlock as _createElementBlock, createCommentVNode as _createCommentVNode, createElementVNode as _createElementVNode, renderList as _renderList, Fragment as _Fragment, pushScopeId as _pushScopeId, popScopeId as _popScopeId } from "vue"

const _withScopeId = n => (_pushScopeId("data-v-834e54c4"),n=n(),_popScopeId(),n)
const _hoisted_1 = ["required", "disabled", "readonly", "placeholder", "name", "value"]
const _hoisted_2 = {
  key: 1,
  class: "fv-plugins-message-container invalid-feedback"
}

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  return (_openBlock(), _createElementBlock(_Fragment, null, [
    (_ctx.label)
      ? (_openBlock(), _createElementBlock("label", {
          key: 0,
          class: _normalizeClass(["fs-5 fw-semibold mb-2", _ctx.required && 'required'])
        }, _toDisplayString(_ctx.label), 3))
      : _createCommentVNode("", true),
    _createElementVNode("input", {
      type: "date",
      class: _normalizeClass(["form-control form-control-solid", { 'is-invalid': _ctx.errors.length }]),
      required: _ctx.required,
      disabled: _ctx.disabled,
      readonly: _ctx.readonly,
      placeholder: _ctx.placeholder,
      name: _ctx.name,
      value: _ctx.modelValue,
      onInput: _cache[0] || (_cache[0] = ($event: any) => (_ctx.$emit('update:modelValue', $event.target.value)))
    }, null, 42, _hoisted_1),
    (_ctx.errors.length)
      ? (_openBlock(), _createElementBlock("div", _hoisted_2, [
          (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(_ctx.errors, (error, i) => {
            return (_openBlock(), _createElementBlock("span", { key: i }, _toDisplayString(error), 1))
          }), 128))
        ]))
      : _createCommentVNode("", true)
  ], 64))
}