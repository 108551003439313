<template>
  <transition-group
    :css="false" tag="div" :class="groupClass"
    @before-enter="beforeEnter" @enter="onEnter"
  >
    <slot></slot>
  </transition-group>
</template>

<script>
import { gsap } from 'gsap'

export default {
  name: 'StaggeredListTransition',
  props: {
    groupClass: {
      type: String,
      default: ''
    }
  },
  methods: {
    beforeEnter (el) {
      el.style.opacity = 0
    },
    onEnter (el, done) {
      gsap.to(el, {
        opacity: 1,
        translateY: '20px',
        delay: el.dataset.index * 0.10,
        onComplete: done
      })
    }
  }
}
</script>

<style scoped>

</style>
