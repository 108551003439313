<template>
  <div v-if="hasContent" class="info-tooltip mt-1 px-2">
    <i
      v-popover="'#info-tooltip'"
      class="fas fa-info-circle fs-6"
    >
      <div class="d-none">
        <slot></slot>
      </div>
    </i>
  </div>
</template>

<script>
import { defineComponent } from 'vue'
import { Popover } from 'bootstrap'

export default defineComponent({
  name: 'InfoTooltip',
  computed: {
    hasContent () {
      return this.$slots.default && !!this.$slots.default()[0].children?.length
    }
  },
  directives: {
    popover: {
      mounted (el) {
        // eslint-disable-next-line @typescript-eslint/no-unused-vars
        const popover = new Popover(el, {
          trigger: 'hover',
          container: el,
          html: true,
          placement: 'auto',
          offset: ({ placement }) => {
            switch (placement) {
              case 'top':
                return [100, 20]
              case 'bottom':
                return [20, 100]
              case 'left':
                return [20, 0]
              case 'right':
                return [0, 20]
              default:
                return [0, 0]
            }
          },
          content: el.querySelector('.d-none')?.innerHTML
        })
      }
    }
  }
})
</script>
