
import { defineComponent } from 'vue'
import KTMenuPages from '@/components/layout/header/menu/MenuPages.vue'
import { headerMenuDisplay } from '@/config/config'

export default defineComponent({
  name: 'header-menu',
  components: {
    KTMenuPages
  },
  setup () {
    return {
      headerMenuDisplay
    }
  }
})
