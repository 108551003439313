
import { defineComponent } from 'vue'
import { contentWidthFluid } from '@/config/config'

export default defineComponent({
  name: 'default-layout-content',
  setup () {
    return {
      contentWidthFluid
    }
  }
})
