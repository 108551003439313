import merge from 'deepmerge'
import layoutConfig from '@/config/DefaultLayoutConfig'
import { Mutations } from '@/store/enums/StoreEnums'
import { Mutation, Module, VuexModule } from 'vuex-module-decorators'
import LayoutConfigTypes from '@/types/layout'
import objectPath from 'object-path'

interface StoreInfo {
  config: LayoutConfigTypes;
  initial: LayoutConfigTypes;
}

type ConfigPayload = {
  property: string;
  // eslint-disable-next-line
  value: any;
}

@Module
export default class ConfigModule extends VuexModule implements StoreInfo {
  config: LayoutConfigTypes = layoutConfig
  initial: LayoutConfigTypes = layoutConfig

  /**
   * Get config from layout config
   * @returns {function(path, defaultValue): *}
   */
  get layoutConfig () {
    return (path: string, defaultValue = '') => {
      return objectPath.get?.(this.config, path, defaultValue)
    }
  }

  @Mutation
  [Mutations.SET_LAYOUT_CONFIG_PROPERTY] ({ property, value }: ConfigPayload): void {
    objectPath.set(this.config, property, value)
    localStorage.setItem('config', JSON.stringify(this.config))
  }

  @Mutation
  [Mutations.RESET_LAYOUT_CONFIG] () {
    this.config = Object.assign({}, this.initial)
  }

  @Mutation
  [Mutations.OVERRIDE_LAYOUT_CONFIG] (): void {
    this.config = this.initial = Object.assign(
      {},
      this.initial,
      JSON.parse(window.localStorage.getItem('config') || '{}')
    )
  }

  @Mutation
  [Mutations.OVERRIDE_PAGE_LAYOUT_CONFIG] (payload: ConfigPayload): void {
    this.config = merge(this.config, payload)
  }
}
