import { resolveComponent as _resolveComponent, createVNode as _createVNode, createElementVNode as _createElementVNode, openBlock as _openBlock, createElementBlock as _createElementBlock, createCommentVNode as _createCommentVNode } from "vue"

const _hoisted_1 = {
  key: 0,
  class: "app-header-menu app-header-mobile-drawer align-items-stretch",
  "data-kt-drawer": "true",
  "data-kt-drawer-name": "app-header-menu",
  "data-kt-drawer-activate": "{default: true, lg: false}",
  "data-kt-drawer-overlay": "true",
  "data-kt-drawer-width": "225px",
  "data-kt-drawer-direction": "end",
  "data-kt-drawer-toggle": "#kt_app_header_menu_toggle",
  "data-kt-swapper": "true",
  "data-kt-swapper-mode": "{default: 'append', lg: 'prepend'}",
  "data-kt-swapper-parent": "{default: '#kt_app_body', lg: '#kt_app_header_wrapper'}"
}
const _hoisted_2 = {
  class: "menu menu-rounded menu-column menu-lg-row my-5 my-lg-0 align-items-stretch fw-semibold px-2 px-lg-0",
  id: "kt_app_header_menu",
  "data-kt-menu": "true"
}
const _hoisted_3 = {
  key: 1,
  class: "align-items-stretch"
}

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_KTMenuPages = _resolveComponent("KTMenuPages")!

  return (_ctx.headerMenuDisplay)
    ? (_openBlock(), _createElementBlock("div", _hoisted_1, [
        _createElementVNode("div", _hoisted_2, [
          _createVNode(_component_KTMenuPages)
        ])
      ]))
    : (_openBlock(), _createElementBlock("div", _hoisted_3))
}