import { createApp } from 'vue'
import App from './App.vue'
import router from './router'
import store from './store'
import ApiService from '@/services/ApiService'
import InlineSvgComponent from 'vue-inline-svg'
import { i18n } from '@/config/i18n'
import vSelect from 'vue-select'

import VueAwesomePaginate from 'vue-awesome-paginate'
import 'vue-awesome-paginate/dist/style.css'

const app = createApp(App)

// eslint-disable-next-line @typescript-eslint/ban-ts-comment
// @ts-ignore
app.use(VueAwesomePaginate)
app.use(i18n)
app.use(store)
app.use(router)
app.component('inline-svg', InlineSvgComponent)
app.component('v-select', vSelect)
ApiService.init(app)

app.mount('#app')
