
import { defineComponent, nextTick, onBeforeMount, onMounted, watch } from 'vue'
import KTHeader from '@/components/layout/header/Header.vue'
import KTSidebar from '@/components/layout/sidebar/Sidebar.vue'
import KTContent from '@/components/layout/Content.vue'
import LayoutService from '@/services/LayoutService'
import { reinitializeComponents } from '@/assets/metronik/ts/keenthemes'
import { useRoute } from 'vue-router'

export default defineComponent({
  name: 'MainLayout',
  components: {
    KTHeader,
    KTSidebar,
    KTContent
  },
  setup () {
    const route = useRoute()

    onBeforeMount(() => {
      LayoutService.init()
    })

    onMounted(() => {
      nextTick(() => {
        reinitializeComponents()
      })
    })

    watch(
      () => route.path,
      () => {
        nextTick(() => {
          reinitializeComponents()
        })
      }
    )
  }
})
