
import { defineComponent, nextTick, onMounted } from 'vue'
import MainLayout from '@/components/layout/MainLayout.vue'
import { useStore } from 'vuex'
import { themeMode } from '@/config/config'
import { Actions, Mutations } from '@/store/enums/StoreEnums'
import { initializeComponents } from '@/assets/metronik/ts/keenthemes'
import { useRoute, useRouter } from 'vue-router'

export default defineComponent({
  name: 'app',
  components: {
    MainLayout
  },
  setup () {
    const store = useStore()
    const route = useRoute()
    const router = useRouter()
    store.commit('initializeStore')

    if (process.env.NODE_ENV === 'development') {
      store.subscribeAction({
        after: (action) => {
          console.log('' +
            '%c[DEV] Store Action Call :%c' + action.type,
          'background: lightblue; padding: 2px; border-radius: 5px 0 0 5px;',
          'background: lightblue; padding: 2px; border-radius: 0 5px 5px 0; font-weight: bold;'
          )
        }
      })

      store.subscribe((mutation) => {
        console.log('' +
          '%c[DEV] Store Mutation Call :%c' + mutation.type,
        'background: lightyellow; padding: 2px; border-radius: 5px 0 0 5px;',
        'background: lightyellow; padding: 2px; border-radius: 0 5px 5px 0; font-weight: bold;'
        )
      })
    }

    onMounted(() => {
      store.commit(Mutations.OVERRIDE_LAYOUT_CONFIG)

      store.dispatch(Actions.SET_THEME_MODE_ACTION, themeMode.value)

      nextTick(() => {
        initializeComponents()

        router.isReady().then(() => {
          const hash = route.hash
          if (route.query.t) {
            if (hash) {
              router.replace({
                query: {},
                hash: hash
              })
            } else {
              router.replace({ query: {} })
            }
          }
        })
      })
    })
  }
})

