export default {
  name: 'VHtmlRouter',
  methods: {
    vHtmlRouter (refs: HTMLElement) {
      const links = refs.querySelectorAll('a')

      links.forEach((link) => {
        const dataTrad = link.getAttribute('data-trad')
        if (dataTrad) {
          // eslint-disable-next-line @typescript-eslint/ban-ts-comment
          // @ts-ignore
          link.innerText = this.$t(dataTrad).toString()

          link.addEventListener('click', (event) => {
            const target = event.target

            // eslint-disable-next-line no-unmodified-loop-condition
            if (target instanceof HTMLAnchorElement) {
              const link = target.getAttribute('href')

              if (link?.substring(0, 4) === 'http') {
                window.location.href = link
              } else {
                // eslint-disable-next-line @typescript-eslint/ban-ts-comment
                // @ts-ignore
                this.$router.push(target.getAttribute('href'))
              }
            }

            event.preventDefault()
          })
        }
      })
    }
  }
}
