import { App } from 'vue'
import axios, { AxiosResponse, AxiosRequestConfig } from 'axios'
import VueAxios from 'vue-axios'
import store from '@/store'
import { Actions, Mutations } from '@/store/enums/StoreEnums'

/**
 * @description service to call HTTP request via Axios
 */
class ApiService {
  public static vueInstance: App

  private static currentUrlTarget = ''

  /**
     * @description Init. Service API
     */
  public static init (app: App<Element>) {
    ApiService.vueInstance = app
    ApiService.vueInstance.use(VueAxios, axios)
    ApiService.vueInstance.axios.defaults.baseURL = process.env.VUE_APP_API_URL
    ApiService.vueInstance.axios.interceptors.response.use(res => {
      if (res.headers['refresh-token']) {
        store.dispatch(Actions.INIT_AUTHENTIFIED_AXIOS, res.headers['refresh-token']).then(() => {
          store.dispatch(Actions.FETCH_USER).then(() => {
            window.location.href = ApiService.currentUrlTarget
          })
        })
      }

      return res
    }, err => {
      if (err.response && err.response.status >= 400) {
        if (err.response.status === 401) {
          ApiService.currentUrlTarget = window.location.pathname
          store.commit(Mutations.LOGOUT)
          if (err.response.data.error.includes('error 10') || err.response.data.error.includes('TOKEN_EXPIRED')) {
            store.dispatch(Actions.REFRESH_USER)
          }
        }
        return Promise.reject(err)
      }
    })
  }

  /**
     * @description set the default HTTP request headers
     */
  public static setHeader (): void {
    ApiService.vueInstance.axios.defaults.headers.common.Accept = 'application/json'
  }

  /**
     * @description send the GET HTTP request
     */
  public static query (
    resource: string,
    params: AxiosRequestConfig
  ): Promise<AxiosResponse> {
    return ApiService.vueInstance.axios.get(resource, params)
  }

  /**
     * @description send the GET HTTP request
     *
     * @template T: response data type
     */
  public static get<T> (
    resource: string,
    config?: AxiosRequestConfig
  ): Promise<AxiosResponse<T>> {
    return ApiService.vueInstance.axios.get<T>(resource, config)
  }

  /**
     * @description set the POST HTTP request
     *
     * @template K: request data type
     * @template V: response data type
     *
     */
  public static post<K, V = K> (
    resource: string,
    data: K,
    params: AxiosRequestConfig = {}
  ): Promise<AxiosResponse<V>> {
    return ApiService.vueInstance.axios.post<V>(resource, data, params)
  }

  // /**
  //  * @description send the UPDATE HTTP request
  //  * @param resource: string
  //  * @param slug: string
  //  * @param params: AxiosRequestConfig
  //  * @returns Promise<AxiosResponse>
  //  */
  // public static update(
  //     resource: string,
  //     slug: string,
  //     params: AxiosRequestConfig
  // ): Promise<AxiosResponse> {
  //     return ApiService.vueInstance.axios.put(`${resource}/${slug}`, params);
  // }

  // /**
  //  * @description Send the PUT HTTP request
  //  * @param resource: string
  //  * @param params: AxiosRequestConfig
  //  * @returns Promise<AxiosResponse>
  //  */
  // public static put(
  //     resource: string,
  //     params: AxiosRequestConfig
  // ): Promise<AxiosResponse> {
  //     return ApiService.vueInstance.axios.put(`${resource}`, params);
  // }

  // /**
  //  * @description Send the DELETE HTTP request
  //  * @param resource: string
  //  * @returns Promise<AxiosResponse>
  //  */
  // public static delete(resource: string): Promise<AxiosResponse> {
  //     return ApiService.vueInstance.axios.delete(resource);
  // }
}

export default ApiService
