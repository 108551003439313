
import { Attachment } from '@/types/api-response'
import { defineComponent, PropType } from 'vue'
import ApiService from '@/services/ApiService'

export default defineComponent({
  name: 'AttachmentFileItem',
  props: {
    attachment: {
      type: Object as PropType<Attachment>,
      required: true
    },
    className: {
      type: String,
      default: ''
    },
    publicFile: {
      type: Boolean,
      default: false
    },
    canDelete: {
      type: Boolean,
      default: false
    }
  },
  computed: {
    mimeIcon () {
      const mime = this.attachment.contentType

      if (!mime) {
        return 'fa-file'
      }

      if (mime.includes('image')) {
        return 'fa-file-image'
      } else if (mime.includes('video')) {
        return 'fa-file-video'
      } else if (mime.includes('audio')) {
        return 'fa-file-audio'
      } else if (mime.includes('pdf')) {
        return 'fa-file-pdf'
      } else if (mime.includes('doc')) {
        return 'fa-file-word'
      } else if (mime.includes('excel')) {
        return 'fa-file-excel'
      } else if (mime.includes('powerpoint')) {
        return 'fa-file-powerpoint'
      } else if (mime.includes('zip')) {
        return 'fa-file-zipper'
      } else if (mime.includes('text')) {
        return 'fa-file-lines'
      } else {
        return 'fa-file'
      }
    },
    sizeToHumanOctet () : string {
      const bytes = this.attachment.size
      const sizes = ['Bytes', 'KB', 'MB', 'GB', 'TB']
      if (bytes === 0) return '0 Byte'
      const i = Math.floor(Math.log(bytes) / Math.log(1024))
      return Math.round(bytes / Math.pow(1024, i)) + ' ' + sizes[i]
    }
  },
  methods: {
    deleteAttachment () {
      this.$emit('delete', this.attachment)
    },
    download () {
      ApiService.get<string>(this.attachment.attachmentUrl, { responseType: 'blob' }).then((response) => {
        const url = window.URL.createObjectURL(new Blob([response.data], { type: 'application/pdf' }))
        window.open(url)
      })
    }
  },
  emits: ['delete']
})
