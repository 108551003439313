import { Module, VuexModule, Mutation, Action } from 'vuex-module-decorators'
import { Actions, Mutations } from '@/store/enums/StoreEnums'
import { HeaderInfos, UserResponse } from '@/types/api-response'
import ApiService from '@/services/ApiService'
import { AdmissibleInfos } from '@/types/layout'

@Module
export default class UserModule extends VuexModule {
  user: UserResponse = {
    userType: 'anon'
  }

  token = ''

  admissibleInfos: AdmissibleInfos = {}

  // noinspection JSUnusedGlobalSymbols
  get getToken () {
    return this.token
  }

  get getAdmissibleInfos () {
    return this.admissibleInfos
  }

  // noinspection JSUnusedGlobalSymbols
  get getUser () {
    return this.user
  }

  // noinspection JSUnusedGlobalSymbols
  get isLoggedIn () {
    return this.user.userType !== 'anon'
  }

  @Mutation
  [Mutations.LOGOUT] () {
    this.user = {
      userType: 'anon'
    }
    this.token = ''
  }

  @Mutation
  [Mutations.SAVE_USER] (payload: UserResponse) {
    this.user = payload
  }

  @Mutation
  [Mutations.SAVE_ADMISSIBLE_INFOS] (payload: HeaderInfos) {
    this.admissibleInfos = {
      admFirstName: payload.firstName,
      admLastName: payload.lastName,
      formationYear: payload.formationYear,
      formationEtape: payload.formationEtape,
      formationLibelle: payload.formationLibelle,
      contractType: payload.contractType,
      school: payload.school
    }
  }

  @Mutation
  [Mutations.SAVE_TOKEN] (token: string) {
    this.token = token
  }

  @Action
  [Actions.INIT_AUTHENTIFIED_AXIOS] (payload: string) {
    ApiService.vueInstance.axios.defaults.headers.common.Authorization = 'Bearer ' + payload
    this.context.commit(Mutations.SAVE_TOKEN, payload)
  }

  @Action
  async [Actions.FETCH_USER] () {
    try {
      const res = await ApiService.get<UserResponse>('/users/me')
      if (res?.data) {
        this.context.commit(Mutations.SAVE_USER, res.data)
      }
    } catch (e) {}
  }

  @Action
  async [Actions.REFRESH_USER] () {
    try {
      await ApiService.get<UserResponse>('/admissibles/refresh_token')
    } catch (e) {}
  }
}
