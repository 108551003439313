import { resolveComponent as _resolveComponent, createVNode as _createVNode, openBlock as _openBlock, createElementBlock as _createElementBlock, createCommentVNode as _createCommentVNode } from "vue"

const _hoisted_1 = {
  key: 0,
  id: "kt_app_sidebar",
  class: "app-sidebar flex-column",
  "data-kt-drawer": "true",
  "data-kt-drawer-name": "app-sidebar",
  "data-kt-drawer-activate": "{default: true, lg: false}",
  "data-kt-drawer-overlay": "true",
  "data-kt-drawer-width": "225px",
  "data-kt-drawer-direction": "start",
  "data-kt-drawer-toggle": "#kt_app_sidebar_mobile_toggle"
}

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_KTSidebarLogo = _resolveComponent("KTSidebarLogo")!
  const _component_KTSidebarMenu = _resolveComponent("KTSidebarMenu")!
  const _component_KTSidebarFooter = _resolveComponent("KTSidebarFooter")!

  return (_ctx.displaySidebar)
    ? (_openBlock(), _createElementBlock("div", _hoisted_1, [
        _createVNode(_component_KTSidebarLogo),
        _createVNode(_component_KTSidebarMenu),
        _createVNode(_component_KTSidebarFooter)
      ]))
    : _createCommentVNode("", true)
}