
import { defineComponent, nextTick } from 'vue'
import BasicCard from '@/components/cards/BasicCard.vue'
import ApiService from '@/services/ApiService'
import { Offer, Partner } from '@/types/portal'
import CardAccordion from '@/components/accordions/CardAccordion.vue'
import dateFormat from 'dateformat'
import { changeDateLocalization } from '@/config/i18n/date'
import { useI18n } from 'vue-i18n'
import { useStore } from 'vuex'
import { Actions } from '@/store/enums/StoreEnums'
import { Atelier, CmsResponse } from '@/types/api-response'
import Swal from 'sweetalert2'
import TextInput from '@/components/forms/TextInput.vue'
import { debounce } from '@/assets/custom/ts/utils/Debouncer'
import DateInput from '@/components/forms/DateInput.vue'
import StaggeredListTransition from '@/components/transitions/StaggeredListTransition.vue'
import { AdmissibleInfos } from '@/types/layout'
import { useRoute } from 'vue-router'

export default defineComponent({
  name: 'PortalSearch',
  components: { StaggeredListTransition, DateInput, TextInput, CardAccordion, BasicCard },
  data: function () {
    return {
      partners: [] as Partner[],
      offers: [] as Offer[],
      offersLoading: false,
      offersCurrentPage: 1,
      offersTotalItems: 0,
      search: {
        query: '',
        publicationDateBefore: '',
        publicationDateAfter: ''
      }
    }
  },
  mounted () {
    const i18n = useI18n()
    const store = useStore()
    const route = useRoute()
    changeDateLocalization(i18n.locale.value)

    nextTick(() => {
      const partnerCall = ApiService.get<Partner[]>('/admissibles/portal/partners').then((response) => {
        this.partners = response.data
      })

      this.offersLoading = true
      const offersCall = this.getOffers()

      Promise.all([partnerCall, offersCall]).then(() => {
        if (route.hash) {
          const anchor = document.getElementById(route.hash.substring(1))
          anchor?.scrollIntoView({
            behavior: 'smooth'
          })
          anchor?.classList.add('card-anchor-focus')
        }
      })

      store.dispatch(Actions.FETCH_CMS_DATA)
    })
  },
  watch: {
    search: {
      handler: function () {
        // eslint-disable-next-line @typescript-eslint/no-empty-function
        this.processQuery('', (val: boolean) => {
          this.offersLoading = val
        }, this)
      },
      deep: true
    },
    offersCurrentPage: {
      handler: function () {
        this.offers = []
        this.offersLoading = true

        setTimeout(() => {
          window.scrollBy({
            top: -10,
            behavior: 'smooth'
          })
        }, 10)

        this.getOffers()
      }
    }
  },
  methods: {
    formatDate (date: string) {
      try {
        return dateFormat(date, 'dddd dd mmmm yyyy')
      } catch (e) {
        return date
      }
    },
    formatHour (date: string) {
      try {
        return dateFormat(date, 'HH:MM')
      } catch (e) {
        return date
      }
    },
    getOffers () {
      return ApiService.get<{
        offers: Offer[],
        totalOffers: number
      }>('/admissibles/portal/offers?page=' + this.offersCurrentPage).then((response) => {
        this.offers = response.data.offers
        this.offersTotalItems = response.data.totalOffers
      }).finally(() => {
        this.offersLoading = false
      })
    },
    processQuery: debounce((term: string, loading: (val: boolean) => void, vm: {
      search: { [key: string]: string },
      offers: Offer[]
      offersTotalItems: number
    }) => {
      loading(true)
      ApiService.get<{
        offers: Offer[],
        totalOffers: number
      }>('/admissibles/portal/offers?' + (new URLSearchParams(vm.search).toString())).then((response) => {
        loading(false)
        vm.offers = response.data.offers
        vm.offersTotalItems = response.data.totalOffers
      })
    }, 200),
    registerAtelier (atelier: Atelier) {
      Swal.fire({
        html: this.$t('views.portal.search.ateliersCard.registerModalConfirmation') + '<br><br>' +
          this.$t('views.portal.search.ateliersCard.registerModalInformations', {
            date: this.formatDate(atelier.date).toLowerCase(),
            start: this.formatHour(atelier.date),
            end: atelier.endHour.substring(0, 5)
          }),
        width: '42em',
        icon: 'info',
        showCancelButton: true,
        confirmButtonText: this.$t('global.buttons.yes'),
        cancelButtonText: this.$t('global.buttons.cancel'),
        reverseButtons: true
      }).then((result) => {
        if (result.isConfirmed) {
          ApiService.post<object, CmsResponse>('/admissibles/portal/registerAtelier/' + atelier.id, {}).then(() => {
            // eslint-disable-next-line @typescript-eslint/ban-ts-comment
            // @ts-ignore
            this.$store.dispatch(Actions.FETCH_CMS_DATA)
            Swal.fire({
              icon: 'success',
              title: this.$t('views.portal.search.ateliersCard.registerModalSuccess'),
              showConfirmButton: true
            })
          })
        }
      })
    },
    unregisterAtelier (atelier: Atelier) {
      Swal.fire({
        text: this.$t('views.portal.search.ateliersCard.unregisterModalConfirmation'),
        width: '36em',
        icon: 'info',
        showCancelButton: true,
        confirmButtonText: this.$t('global.buttons.yes'),
        cancelButtonText: this.$t('global.buttons.cancel'),
        reverseButtons: true
      }).then((result) => {
        if (result.isConfirmed) {
          ApiService.post<object, CmsResponse>('/admissibles/portal/registerAtelier/' + atelier.id, {}).then(() => {
            // eslint-disable-next-line @typescript-eslint/ban-ts-comment
            // @ts-ignore
            this.$store.dispatch(Actions.FETCH_CMS_DATA)
            Swal.fire({
              icon: 'success',
              title: this.$t('views.portal.search.ateliersCard.unregisterModalSuccess'),
              showConfirmButton: true
            })
          })
        }
      })
    }
  },
  computed: {
    cmsData (): CmsResponse {
      return useStore().getters.getCmsData
    },
    admissibleInfos (): AdmissibleInfos {
      return useStore().getters.getAdmissibleInfos
    }
  }
})
