import { renderSlot as _renderSlot, resolveDirective as _resolveDirective, openBlock as _openBlock, createElementBlock as _createElementBlock, withDirectives as _withDirectives, createElementVNode as _createElementVNode, createCommentVNode as _createCommentVNode, normalizeClass as _normalizeClass, pushScopeId as _pushScopeId, popScopeId as _popScopeId } from "vue"

const _withScopeId = n => (_pushScopeId("data-v-666c6cc0"),n=n(),_popScopeId(),n)
const _hoisted_1 = { class: "d-flex flex-stack flex-wrap" }
const _hoisted_2 = ["data-bs-target", "aria-controls"]
const _hoisted_3 = ["id"]
const _hoisted_4 = { class: "d-flex flex-wrap py-5 ps-10" }
const _hoisted_5 = {
  key: 0,
  class: "card-footer pt-5 pb-0"
}

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _directive_collapse = _resolveDirective("collapse")!

  return (_openBlock(), _createElementBlock("div", {
    class: _normalizeClass(["card px-3 py-5", 'card-' + $props.color])
  }, [
    _createElementVNode("div", _hoisted_1, [
      _withDirectives((_openBlock(), _createElementBlock("div", {
        class: "d-flex align-items-center collapsible rotate collapsed w-100",
        "data-bs-toggle": "collapse",
        "data-bs-target": '#accordion_' + $props.context + '_' + $props.idKey,
        role: "button",
        "aria-controls": 'accordion_' + $props.context + '_' + $props.idKey
      }, [
        _renderSlot(_ctx.$slots, "header", {}, undefined, true)
      ], 8, _hoisted_2)), [
        [_directive_collapse]
      ])
    ]),
    _createElementVNode("div", {
      id: 'accordion_' + $props.context + '_' + $props.idKey,
      class: _normalizeClass(["fs-6 collapse", { show: $props.expanded }]),
      style: {}
    }, [
      _createElementVNode("div", _hoisted_4, [
        _renderSlot(_ctx.$slots, "content", {}, undefined, true)
      ]),
      (_ctx.$slots.footer)
        ? (_openBlock(), _createElementBlock("div", _hoisted_5, [
            _renderSlot(_ctx.$slots, "footer", {}, undefined, true)
          ]))
        : _createCommentVNode("", true)
    ], 10, _hoisted_3)
  ], 2))
}