
import { defineComponent } from 'vue'
import KTHeaderMenu from '@/components/layout/header/menu/Menu.vue'
import KTHeaderNavbar from '@/components/layout/header/Navbar.vue'
import {
  layout,
  headerWidthFluid,
  themeMode,
  headerDisplay
} from '@/config/config'
import { useStore } from 'vuex'
import { AdmissibleInfos } from '@/types/layout'
import { UserResponse } from '@/types/api-response'

export default defineComponent({
  name: 'layout-header',
  components: {
    KTHeaderMenu,
    KTHeaderNavbar
  },
  setup () {
    return {
      layout,
      headerWidthFluid,
      headerDisplay,
      themeMode
    }
  },
  computed: {
    admissibleInfos () : AdmissibleInfos {
      return useStore().getters.getAdmissibleInfos
    },
    user (): UserResponse {
      return useStore().getters.getUser
    }
  }
})
