
import { defineComponent, computed } from 'vue'
import KTThemeModeSwitcher from '@/components/layout/header/ThemeModeSwitcher.vue'
import { useStore } from 'vuex'
import UserAccountMenu from '@/components/layout/header/menu/UserAccountMenu.vue'

export default defineComponent({
  name: 'header-navbar',
  components: {
    // KTSearch,
    KTThemeModeSwitcher,
    UserAccountMenu
  },
  setup () {
    const store = useStore()

    const themeMode = computed(() => {
      return store.getters.getThemeMode
    })

    return {
      themeMode
    }
  },
  computed: {
    user () {
      return useStore().getters.getUser
    }
  }
})
