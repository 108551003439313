import { computed } from 'vue'
import { i18n } from '@/config/i18n'

export default [
  {
    path: '/unauthorized',
    name: 'unauthorized',
    // code-splitting au niveau de la route
    // permet de générer un chunk différent par route, pour favoriser le lazy-loading et le temps de chargement initial
    // à utiliser de la même manière sur toutes les routes
    component: () => import(/* webpackChunkName: "unauthorized" */ '../views/Unauthorized.vue'),
    meta: {
      title: computed(() => i18n.global.t('meta.titles.unauthorized'))
    }
  },
  {
    path: '/forbidden',
    name: 'forbidden',
    component: () => import(/* webpackChunkName: "forbidden" */ '../views/Forbidden.vue'),
    meta: {
      title: computed(() => i18n.global.t('meta.titles.forbidden'))
    }
  },
  {
    path: '/archived',
    name: 'archived',
    component: () => import(/* webpackChunkName: "archived" */ '../views/Archived.vue'),
    meta: {
      title: computed(() => i18n.global.t('meta.titles.gone'))
    }
  },
  {
    path: '/404',
    name: '404',
    component: () => import(/* webpackChunkName: "404" */ '../views/NotFound.vue'),
    meta: {
      title: computed(() => i18n.global.t('meta.titles.notFound'))
    }
  }
]
