
import { Collapse } from 'bootstrap'

export default {
  name: 'CardAccordion',
  props: {
    title: String,
    label: {
      type: String,
      required: false
    },
    idKey: Number,
    expanded: {
      type: Boolean,
      default: false
    },
    context: String,
    logo: {
      type: String,
      required: false
    },
    color: {
      type: String,
      default: 'secondary'
    }
  },
  directives: {
    collapse: {
      // eslint-disable-next-line @typescript-eslint/ban-ts-comment
      // @ts-ignore
      mounted (el: HTMLElement, { instance }) {
        // eslint-disable-next-line no-new
        new Collapse(el, {
          toggle: instance.expanded
        })
      }
    }
  }
}
