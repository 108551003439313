import { createRouter, createWebHistory, RouteRecordRaw } from 'vue-router'
import { i18n } from '@/config/i18n'
import { computed, ComputedRef } from 'vue'
import store from '@/store'
import { Actions } from '@/store/enums/StoreEnums'
import ErrorRoutes from '@/router/errors'
import PortalRoutes from '@/router/portal'

const routes: Array<RouteRecordRaw> = [
  ...ErrorRoutes,
  ...PortalRoutes,
  {
    path: '/logout',
    name: 'logout',
    component: () => import(/* webpackChunkName: "logout" */ '../views/Logout.vue'),
    meta: {
      title: computed(() => i18n.global.t('meta.titles.home')),
      ignoreAuth: true
    }
  },
  {
    path: '/admissible/fiche-premier-contact/:id',
    name: 'form-admissible-contact',
    component: () => import(/* webpackChunkName: "form-admissible-contact" */ '../views/FormAdmissibleContact.vue'),
    meta: {
      title: computed(() => i18n.global.t('meta.titles.f1ca')),
      userTypeRequired: 'admissible'
    },
    props: true
  },
  {
    path: '/employeur/fiche-premier-contact/:id',
    name: 'form-employeur-contact',
    component: () => import(/* webpackChunkName: "form-employeur-contact" */ '../views/FormCompanyContact.vue'),
    meta: {
      title: computed(() => i18n.global.t('meta.titles.f1ce')),
      userTypeRequired: 'company'
    },
    props: true
  },
  {
    path: '/employeur/modification-missions/:id',
    name: 'form-edit-missions',
    component: () => import(/* webpackChunkName: "form-funding-validation" */ '../views/FormEditMissions.vue'),
    meta: {
      title: computed(() => i18n.global.t('meta.titles.editMissions')),
      userTypeRequired: 'company'
    },
    props: true
  },
  {
    path: '/employeur/validation-financement/:id',
    name: 'form-funding-validation',
    component: () => import(/* webpackChunkName: "form-funding-validation" */ '../views/FormFundingValidation.vue'),
    meta: {
      title: computed(() => i18n.global.t('meta.titles.fundingValidation')),
      userTypeRequired: 'company'
    },
    props: true
  },
  {
    path: '/admissible/fiche-renseignement/:id',
    name: 'form-admissible-info-sheet',
    component: () => import(/* webpackChunkName: "form-admissible-info-sheet" */ '../views/info-sheet/FormAdmissibleInfoSheet.vue'),
    meta: {
      title: computed(() => i18n.global.t('meta.titles.informationSheet')),
      userTypeRequired: 'admissible'
    },
    props: true
  },
  {
    path: '/admissible/fiche-renseignements/:id',
    name: 'form-admissible-info-sheets',
    component: () => import(/* webpackChunkName: "form-admissible-info-sheet" */ '../views/info-sheet/FormAdmissibleInfoSheet.vue'),
    meta: {
      title: computed(() => i18n.global.t('meta.titles.informationSheet')),
      userTypeRequired: 'admissible'
    },
    props: true
  },
  {
    path: '/employeur/fiche-renseignement/:id',
    name: 'form-company-info-sheet',
    component: () => import(/* webpackChunkName: "form-company-info-sheet" */ '../views/info-sheet/FormCompanyInfoSheet.vue'),
    meta: {
      title: computed(() => i18n.global.t('meta.titles.informationSheet')),
      userTypeRequired: 'company'
    },
    props: true
  },
  {
    path: '/employeur/fiche-renseignements/:id',
    name: 'form-company-info-sheets',
    component: () => import(/* webpackChunkName: "form-company-info-sheet" */ '../views/info-sheet/FormCompanyInfoSheet.vue'),
    meta: {
      title: computed(() => i18n.global.t('meta.titles.informationSheet')),
      userTypeRequired: 'company'
    },
    props: true
  },
  {
    path: '/signature/:id',
    name: 'form-signature',
    component: () => import(/* webpackChunkName: "form-signature" */ '../views/signature/FormSignature.vue'),
    meta: {
      title: computed(() => i18n.global.t('meta.titles.signature'))
    },
    props: true
  },
  {
    path: '/:catchAll(.*)', redirect: 'unauthorized'
  },
  {
    path: '/sondage/:etape/:id',
    name: 'form-survey',
    component: () => import(/* webpackChunkName: "form-survey" */ '../views/FormSurvey.vue'),
    meta: {
      title: computed(() => i18n.global.t('meta.titles.survey'))
    },
    props: true
  },
  {
    path: '/:catchAll(.*)', redirect: 'unauthorized'
  }
]

const router = createRouter({
  history: createWebHistory(process.env.BASE_URL),
  routes
})

router.beforeEach((to, from, next) => {
  if (to.meta.title && (<ComputedRef<string>>to.meta.title).value) {
    document.title = (to.meta.title ? (to.meta.title as ComputedRef<string>).value + ' - ' : '') + i18n.global.t('global.title')
  } else {
    document.title = (to.meta.title ? to.meta.title + ' - ' : '') + i18n.global.t('global.title')
  }

  if (to.meta.ignoreAuth) {
    next()
    return
  }

  const tokenToLoad = to.query?.t || store.getters.getToken

  if (to.meta.userTypeRequired) {
    if (tokenToLoad) {
      store.dispatch(Actions.INIT_AUTHENTIFIED_AXIOS, tokenToLoad).then(() => {
        store.dispatch(Actions.FETCH_USER).then(() => {
          if (to.meta.userTypeRequired && (store.getters.getUser.userType !== to.meta.userTypeRequired)) {
            next({ name: 'forbidden' })
            return
          }
          next()
        })
      })
    } else {
      next({ name: 'unauthorized' })
    }
  } else {
    store.dispatch(Actions.INIT_AUTHENTIFIED_AXIOS, tokenToLoad)
    store.dispatch(Actions.FETCH_USER)
    next()
  }
})

export default router
