
import { defineComponent, PropType } from 'vue'
import InfoTooltip from '@/components/elements/InfoTooltip.vue'

export default defineComponent({
  name: 'TextInput',
  components: { InfoTooltip },
  props: {
    modelValue: {
      type: [String, Number],
      default: ''
    },
    label: {
      type: String,
      default: ''
    },
    name: {
      type: String,
      default: ''
    },
    placeholder: {
      type: String,
      default: ''
    },
    inlineIcon: {
      type: String,
      default: null
    },
    required: {
      type: Boolean,
      default: false
    },
    errors: {
      type: Object as PropType<string[]>,
      default: () => ({})
    },
    readonly: {
      type: Boolean,
      default: false
    },
    disabled: {
      type: Boolean,
      default: false
    }
  },
  emits: ['update:modelValue']
})

