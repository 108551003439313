import { toDisplayString as _toDisplayString, createElementVNode as _createElementVNode, normalizeClass as _normalizeClass, openBlock as _openBlock, createElementBlock as _createElementBlock, createCommentVNode as _createCommentVNode, renderSlot as _renderSlot } from "vue"

const _hoisted_1 = { class: "card-title" }
const _hoisted_2 = { class: "fw-bold mb-0" }
const _hoisted_3 = { class: "card-body" }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  return (_openBlock(), _createElementBlock("div", {
    class: _normalizeClass(["card", { 'explicit-containment': _ctx.explicitContainment }])
  }, [
    (_ctx.title)
      ? (_openBlock(), _createElementBlock("div", {
          key: 0,
          class: _normalizeClass(["card-header", { 'no-inline-border': _ctx.noInlineBorder }])
        }, [
          _createElementVNode("div", _hoisted_1, [
            _createElementVNode("h2", _hoisted_2, _toDisplayString(_ctx.title), 1)
          ])
        ], 2))
      : _createCommentVNode("", true),
    _createElementVNode("div", _hoisted_3, [
      _renderSlot(_ctx.$slots, "default")
    ])
  ], 2))
}