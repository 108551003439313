import { Module, VuexModule, Mutation, Action } from 'vuex-module-decorators'
import { Actions, Mutations } from '@/store/enums/StoreEnums'
import { CmsResponse } from '@/types/api-response'
import ApiService from '@/services/ApiService'
import { i18n } from '@/config/i18n'

@Module
export default class PortalModule extends VuexModule {
  cms: CmsResponse = {}

  // noinspection JSUnusedGlobalSymbols
  get getCmsData () {
    return this.cms
  }

  @Mutation
  [Mutations.SAVE_CMS] (payload: CmsResponse) {
    this.cms = payload
  }

  @Action
  async [Actions.FETCH_CMS_DATA] () {
    try {
      const res = await ApiService.get<CmsResponse>('/admissibles/portal/cms?lang=' + i18n.global.locale.value.split('-')[0])
      if (res?.data) {
        this.context.commit(Mutations.SAVE_CMS, res.data)
        this.context.commit(Mutations.SAVE_ADMISSIBLE_INFOS, res.data.headerInfos)
      }
    } catch (e) {}
  }
}
