
import { defineComponent } from 'vue'

export default defineComponent({
  name: 'WizardItem',
  props: {
    title: {
      type: String,
      default: ''
    },
    icon: {
      type: String,
      default: ''
    },
    completed: {
      type: Boolean,
      default: false
    },
    active: {
      type: Boolean,
      default: false
    },
    isLast: {
      type: Boolean,
      default: false
    }
  },
  computed: {
    state (): string {
      if (this.completed) {
        return 'done'
      }

      if (this.active) {
        return 'current'
      }

      return 'pending'
    }
  }
})
