export function debounce<T extends (...args: never[]) => void>(fn: T, delay: number): T {
  let timeoutID: number | undefined

  return function (this: never, ...args: never[]) {
    clearTimeout(timeoutID)
    timeoutID = setTimeout(() => {
      fn.apply(this, args)
    }, delay)
  } as T
}
