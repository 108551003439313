import { normalizeClass as _normalizeClass, createElementVNode as _createElementVNode, toDisplayString as _toDisplayString, openBlock as _openBlock, createElementBlock as _createElementBlock, createCommentVNode as _createCommentVNode, pushScopeId as _pushScopeId, popScopeId as _popScopeId } from "vue"

const _withScopeId = n => (_pushScopeId("data-v-3ef94fc2"),n=n(),_popScopeId(),n)
const _hoisted_1 = ["data-wizard-state"]
const _hoisted_2 = { class: "wizard-label" }
const _hoisted_3 = { class: "wizard-title fw-bold" }
const _hoisted_4 = {
  key: 0,
  class: "wizard-arrow fa-solid fa-chevron-right"
}

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  return (_openBlock(), _createElementBlock("div", {
    "data-wizard-type": "step",
    "data-wizard-state": _ctx.state,
    class: "wizard-step"
  }, [
    _createElementVNode("div", _hoisted_2, [
      _createElementVNode("i", {
        class: _normalizeClass('wizard-icon ' + _ctx.icon )
      }, null, 2),
      _createElementVNode("h3", _hoisted_3, _toDisplayString(_ctx.title), 1)
    ]),
    (!_ctx.isLast)
      ? (_openBlock(), _createElementBlock("i", _hoisted_4))
      : _createCommentVNode("", true)
  ], 8, _hoisted_1))
}