
import { defineComponent, nextTick } from 'vue'
import BasicCard from '@/components/cards/BasicCard.vue'
import ApiService from '@/services/ApiService'
import { Dossier } from '@/types/portal'
import CardAccordion from '@/components/accordions/CardAccordion.vue'
import dateFormat from 'dateformat'
import WizardItem from '@/components/wizard/WizardItem.vue'
import WizardContainer from '@/components/wizard/WizardContainer.vue'
import { useI18n } from 'vue-i18n'
import { changeDateLocalization } from '@/config/i18n/date'
import { ElementAnimateUtil } from '@/assets/metronik/ts/_utils'
import FileInput from '@/components/forms/FileInput.vue'
import AdmissibleDocuments from '@/views/sub/AdmissibleDocuments.vue'
import { useStore } from 'vuex'
import { CmsResponse } from '@/types/api-response'
import { Actions } from '@/store/enums/StoreEnums'

export default defineComponent({
  name: 'PortalCases',
  components: { AdmissibleDocuments, WizardContainer, WizardItem, CardAccordion, BasicCard },
  data: function () {
    return {
      dossiers: [] as Dossier[]
    }
  },
  mounted () {
    ElementAnimateUtil.scrollTop(0, 500)
    const i18n = useI18n()
    const store = useStore()
    changeDateLocalization(i18n.locale.value)

    nextTick(() => {
      ApiService.get<Dossier[]>('/admissibles/portal/dossiers').then((response) => {
        this.dossiers = response.data
      })
    })

    store.dispatch(Actions.FETCH_CMS_DATA)
  },
  computed: {
    cmsData (): CmsResponse {
      return useStore().getters.getCmsData
    }
  },
  methods: {
    formatDate (date: string) {
      try {
        if (window.innerWidth < 768) {
          return dateFormat(date, 'dd/MM/yyyy')
        } else {
          return dateFormat(date, 'dddd dd mmmm yyyy')
        }
      } catch (e) {
        return date
      }
    },
    handleClick (event: Event) {
      this.$router.push((event.target as HTMLButtonElement).attributes.getNamedItem('data-href')?.value ?? '#')
      event.stopPropagation()
      event.preventDefault()
    },
    handleCerfa (event: Event, url: string) {
      ApiService.get<string>(url, { responseType: 'blob' }).then((response) => {
        const url = window.URL.createObjectURL(new Blob([response.data], { type: 'application/pdf' }))
        window.open(url)
      })

      event.stopPropagation()
      event.preventDefault()
    }
  }
})
