
import { defineComponent, nextTick } from 'vue'
import BasicCard from '@/components/cards/BasicCard.vue'
import { Actions } from '@/store/enums/StoreEnums'
import { Store } from 'vuex'
import { CmsResponse, PublicFile } from '@/types/api-response'
import VHtmlRouter from '@/services/mixins/VHtmlRouter'
import { ElementAnimateUtil } from '@/assets/metronik/ts/_utils'
import ApiService from '@/services/ApiService'
import AttachmentFileItem from '@/components/elements/AttachmentFileItem.vue'

export default defineComponent({
  name: 'PortalHome',
  components: { AttachmentFileItem, BasicCard },
  mixins: [VHtmlRouter],
  data: function () {
    return {
      flashText: '' as string,
      publicFiles: [] as PublicFile[]
    }
  },
  mounted () {
    ElementAnimateUtil.scrollTop(0, 500)
  },
  watch: {
    '$i18n.locale': {
      handler () {
        // eslint-disable-next-line @typescript-eslint/ban-ts-comment
        // @ts-ignore
        this.$store.dispatch(Actions.FETCH_CMS_DATA).then(() => {
          if (this.cmsData.cmsInfo?.information?.title) {
            this.vHtmlRouter(this.$refs.information as HTMLElement)
          }
        })
      },
      immediate: true
    },
    cmsData: {
      handler () {
        if (!this.cmsData.currentDossierId) return

        ApiService.get<PublicFile[]>('/admissibles/file/getPublicFiles/' + this.cmsData.currentDossierId + '?type=home').then((response) => {
          this.publicFiles = response.data
        }).then(() => {
          nextTick(() => {
            if (this.$route.hash) {
              const anchor = document.getElementById(this.$route.hash.substring(1))
              anchor?.classList.add('card-anchor-focus')
            }
          })
        })
      }
    }
  },
  computed: {
    cmsData (): CmsResponse {
      return (this.$store as Store<never>).getters.getCmsData
    }
  }
})
