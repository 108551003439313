
import { defineComponent } from 'vue'
import { layout, themeMode, sidebarToggleDisplay } from '@/config/config'

export default defineComponent({
  name: 'sidebar-logo',
  components: {},
  setup () {
    return {
      layout,
      themeMode,
      sidebarToggleDisplay
    }
  }
})
