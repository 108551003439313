import { computed } from 'vue'
import { i18n } from '@/config/i18n'
import PortalHome from '@/views/portal/PortalHome.vue'
import PortalSearch from '@/views/portal/PortalSearch.vue'
import PortalCases from '@/views/portal/PortalCases.vue'

export default [
  {
    path: '/espace-admissible',
    name: 'portal',
    component: () => import(/* webpackChunkName: "portal" */ '../components/layout/portal/PortalContainer.vue'),
    children: [
      {
        path: '',
        name: 'portal-home',
        component: PortalHome,
        meta: {
          title: computed(() => i18n.global.t('meta.titles.portal.home')),
          userTypeRequired: 'admissible'
        },
        props: true
      },
      {
        path: 'recherche',
        name: 'portal-search',
        component: PortalSearch,
        meta: {
          title: computed(() => i18n.global.t('meta.titles.portal.home')),
          userTypeRequired: 'admissible'
        },
        props: true
      },
      {
        path: 'dossiers',
        name: 'portal-cases',
        component: PortalCases,
        meta: {
          title: computed(() => i18n.global.t('meta.titles.portal.home')),
          userTypeRequired: 'admissible'
        },
        props: true
      }
    ]
  }
]
