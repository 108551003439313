
import { defineComponent } from 'vue'
import { displaySidebar } from '@/config/config'
import KTSidebarLogo from '@/components/layout/sidebar/SidebarLogo.vue'
import KTSidebarMenu from '@/components/layout/sidebar/SidebarMenu.vue'
import KTSidebarFooter from '@/components/layout/sidebar/SidebarFooter.vue'

export default defineComponent({
  name: 'theme-sidebar',
  components: {
    KTSidebarLogo,
    KTSidebarMenu,
    KTSidebarFooter
  },
  setup () {
    return {
      displaySidebar
    }
  }
})
