
import { defineComponent, computed } from 'vue'
import { useI18n } from 'vue-i18n'
import { useStore } from 'vuex'
import { useRouter } from 'vue-router'
import { UserResponse } from '@/types/api-response'

export default defineComponent({
  name: 'kt-user-menu',
  components: {},
  setup () {
    const router = useRouter()
    const i18n = useI18n()

    i18n.locale.value = localStorage.getItem('lang')
      ? (localStorage.getItem('lang') as string)
      : 'fr-FR'

    const countries: { [key: string]: { flag: string, name: string } } = {
      'fr-FR': {
        flag: '/assets/flags/france.svg',
        name: 'Français'
      },
      'en-US': {
        flag: '/assets/flags/uk.svg',
        name: 'English'
      }
    }

    const signOut = () => {
      router.push({ name: 'logout' })
    }

    const setLang = (lang: string) => {
      localStorage.setItem('lang', lang)
      i18n.locale.value = lang
    }

    const currentLanguage = (lang: string) => {
      return i18n.locale.value === lang
    }

    const currentLanguageLocale = computed(() => {
      return countries[i18n.locale.value]
    })

    return {
      signOut,
      setLang,
      currentLanguage,
      currentLanguageLocale,
      countries
    }
  },
  computed: {
    user (): UserResponse {
      return useStore().getters.getUser
    }
  }
})
